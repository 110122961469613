import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Error } from '@/components/Common/Error/Error';

import './App.scss';
import { useAuthContext, AuthProvider } from '@/context/Authentication.context';
import { ProductProvider } from '@/context/Product.context';
import { QualityControlProvider } from '@/context/Qualitycontrol.context';
import { WAREHOUSE_CONFIG } from '@/helpers/local-storage';
import { RoutesMapping } from '@/helpers/routing';
import { DEFAULT_UNIT_CONFIG, UNIT_CONFIG } from '@/helpers/unit';
import { useAgentInfo } from '@/hooks/use-agent';
import AgentActivity from '@/pages/AgentActivity';
import { InputSearch } from '@/pages/InputSearch';
import QcList from '@/pages/QcList';
import { QualityControlTool } from '@/pages/QualityControlTool';
import TakePhoto from '@/pages/TakePhoto';
import { WAREHOUSE_UNIT_TYPE } from '@/types/Warehouse';
import Layout from './Layout';
import LoginContainer from '../components/Common/LoginContainer/LoginContainer';

const PageLayout = () => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuthContext();
  const { id: productId } = useParams();

  const { agent } = useAgentInfo();

  const { warehouse: { measurementSystem = WAREHOUSE_UNIT_TYPE.FR } = {} } =
    agent || {};

  useEffect(() => {
    const warehouseConfig = measurementSystem
      ? UNIT_CONFIG[measurementSystem] || DEFAULT_UNIT_CONFIG
      : DEFAULT_UNIT_CONFIG;

    localStorage.setItem(WAREHOUSE_CONFIG, JSON.stringify(warehouseConfig));
  }, [measurementSystem]);

  const isQcToolPage = pathname === `${RoutesMapping.QC_TOOL}/${productId}`;
  const isHomepage = pathname === RoutesMapping.HOME;

  const PageContent = () => {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  };

  return (
    <>
      <LoginContainer />
      {isAuthenticated && (
        <Box
          className="page-container"
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          })}
        >
          {isHomepage || isQcToolPage ? (
            // Wrap ProductProvider and QualityControlProvider outside of the router
            // to make sure the context values persist when navigating between pages
            // PLEASE DO NOT TOUCH THIS UNLESS YOU KNOW WHAT YOU ARE DOING
            <ProductProvider>
              {isQcToolPage ? (
                <QualityControlProvider>
                  <PageContent />
                </QualityControlProvider>
              ) : (
                <PageContent />
              )}
            </ProductProvider>
          ) : (
            <PageContent />
          )}
        </Box>
      )}
    </>
  );
};

const router = createBrowserRouter([
  {
    id: 'root',
    path: RoutesMapping.HOME,
    element: <PageLayout />,
    children: [
      {
        index: true,
        element: <InputSearch />,
      },
      {
        path: `${RoutesMapping.QC_TOOL}/:id`,
        element: <QualityControlTool />,
      },
      {
        path: RoutesMapping.QC_LIST,
        element: <QcList />,
      },
      {
        path: RoutesMapping.AGENT_PRODUCTIVITY,
        element: <AgentActivity />,
      },
    ],
    errorElement: <Error />,
  },
]);

const unAuthenticatedRouter = createBrowserRouter([
  {
    id: 'take-photo',
    path: '/take-photo',
    element: <TakePhoto />,
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10,
    },
  },
});

export default function App() {
  // This is for take photo testing, will be removed later
  const unauthenticatedRoutes = ['take-photo'];

  const isUnauthenticatedRoute = unauthenticatedRoutes.some((p) =>
    window.location.pathname.includes(p)
  );

  return (
    <QueryClientProvider client={queryClient}>
      {isUnauthenticatedRoute ? (
        <RouterProvider router={unAuthenticatedRouter} />
      ) : (
        <AuthProvider>
          <RouterProvider
            router={router}
            fallbackElement={<p>Initial Load...</p>}
          />
        </AuthProvider>
      )}

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
