import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CheckPointType } from '@/types/Checkpoint';
import { QcInfoType } from '@/types/QcInfo';
import { getCheckPointQueryKey } from './use-checkpoints';
import { useProduct } from './use-product';

const useSkipCheckpoint = () => {
  const queryClient = useQueryClient();

  const { qcData, setQcData } = useProduct();

  const updateCheckpointCache = (
    uuid: string,
    id: number,
    skipped: boolean
  ) => {
    const queryKey = getCheckPointQueryKey(uuid);

    const checkpointCache =
      queryClient.getQueryData<CheckPointType[]>(queryKey);

    if (!checkpointCache || !checkpointCache?.length) return;

    const checkpoint = checkpointCache.find((c) => c.id === id);

    if (!checkpoint) return;

    checkpoint.isSkipped = skipped;

    queryClient.setQueryData<CheckPointType[]>(queryKey, checkpointCache);
  };

  const updateQcData = (checkpointId: number, skipped: boolean) => {
    if ((qcData as QcInfoType)?.product) {
      const updatedData = qcData as QcInfoType;

      const recordCheckpoints = updatedData.record?.checkpointRecords?.find(
        (r) => r.checkpoint.id === checkpointId
      );

      if (recordCheckpoints) {
        recordCheckpoints.checkpoint.isSkipped = skipped;
        setQcData(updatedData);
      }
    }
  };

  return useMutation({
    onMutate: ({
      uuid,
      id,
      skipped,
    }: {
      uuid: string;
      id: number;
      skipped: boolean;
    }) => {
      // Available checkpoints api
      updateCheckpointCache(uuid, id, skipped);

      // Checkpoints in qc Record data
      updateQcData(id, skipped);
    },
  });
};

export default useSkipCheckpoint;
